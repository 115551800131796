import { VForm } from 'vuetify/lib/components/VForm';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "interest-table-component-container" },
    [
      _c(
        "div",
        [
          _c(
            VForm,
            {
              staticClass: "interest-table-component-filter-container",
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.getAccessesByPage($event)
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "span",
                    { staticClass: "interests-table-component-filters-label" },
                    [_vm._v(_vm._s(_vm.$t("search_investors")))]
                  ),
                  _c(VTextField, {
                    staticClass:
                      "interests-table-component-filters-auto-complete",
                    attrs: { color: "primary", dense: "", outlined: "" },
                    model: {
                      value: _vm.searchInput,
                      callback: function ($$v) {
                        _vm.searchInput = $$v
                      },
                      expression: "searchInput",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "interest-table-component-button-filter" },
                [
                  _c("NewDxaButton", {
                    attrs: {
                      type: "submit",
                      outline: true,
                      title: _vm.$tc("filter_kpi", 1),
                      loading: _vm.loading,
                    },
                    on: { btnFunction: _vm.getAccessByPage },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "box-loading" },
            [
              _c(VProgressCircular, {
                attrs: { indeterminate: "", size: "70", color: "primary" },
              }),
            ],
            1
          )
        : _c(VSimpleTable, [
            _c("thead", { staticClass: "interest-table-thead" }, [
              _c("th", { staticClass: "text-left" }, [
                _c(
                  "div",
                  { staticClass: "table-thead-flex" },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("investor_name")) + " "),
                    _c("font-awesome-icon", {
                      staticClass: "arrow-table-icon",
                      attrs: {
                        color:
                          "" +
                          (_vm.changeIconOrder === _vm.orderFilterEnum.INVESTOR
                            ? "var(--primary)"
                            : "#AFAFAF"),
                        icon:
                          "fa-solid " +
                          (_vm.changeIconOrder === _vm.orderFilterEnum.INVESTOR
                            ? "fa-chevron-up"
                            : "fa-chevron-down"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.headerClick(_vm.orderFilterEnum.INVESTOR)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("th", { staticClass: "text-left" }, [
                _c("div", { staticClass: "table-thead-flex" }, [
                  _vm._v(_vm._s(_vm.$t("access_granted"))),
                ]),
              ]),
              _c("th", { staticClass: "text-left" }, [
                _c(
                  "div",
                  { staticClass: "table-thead-flex" },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("viewed_last")) + " "),
                    _c("font-awesome-icon", {
                      staticClass: "arrow-table-icon",
                      attrs: {
                        color:
                          "" +
                          (_vm.changeIconOrder ===
                          _vm.orderFilterEnum.VIEWED_LAST
                            ? "var(--primary)"
                            : "#AFAFAF"),
                        icon:
                          "fa-solid " +
                          (_vm.changeIconOrder ===
                          _vm.orderFilterEnum.VIEWED_LAST
                            ? "fa-chevron-up"
                            : "fa-chevron-down"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.headerClick(
                            _vm.orderFilterEnum.VIEWED_LAST
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "tbody",
              { staticClass: "interest-table-body" },
              _vm._l(_vm.accesses, function (access, i) {
                return _c("tr", { key: i }, [
                  _c(
                    "td",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goInvestorProfile(access.investorId)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { id: "interest-table-body-investor-name" } },
                        [_vm._v(" " + _vm._s(access.investorFullName) + " ")]
                      ),
                    ]
                  ),
                  _c("td", [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formatDate(access.accessGrantedDate))),
                    ]),
                  ]),
                  _c("td", [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formatDate(access.lastAccessDate))),
                    ]),
                  ]),
                ])
              }),
              0
            ),
          ]),
      !_vm.loading
        ? _c(VPagination, {
            staticClass: "mt-6",
            attrs: { color: "primary", length: _vm.pageCount },
            on: { input: _vm.changePage },
            model: {
              value: _vm.page,
              callback: function ($$v) {
                _vm.page = $$v
              },
              expression: "page",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }