import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "min-height": "100vh" }, attrs: { id: "rounds-bg" } },
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "round-loading" },
            [
              _c(VProgressCircular, {
                attrs: { indeterminate: "", size: "70", color: "primary" },
              }),
            ],
            1
          )
        : _c("div", { attrs: { id: "rounds-content" } }, [
            _c("div", { staticClass: "round-accesses-header" }, [
              _c(
                "a",
                {
                  staticClass: "round-accesses-header-back-page",
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "round-accesses-header-back-page-icon",
                    attrs: { icon: "fa-solid fa-arrow-left" },
                  }),
                  _c(
                    "span",
                    { staticClass: "round-accesses-header-back-page-text" },
                    [
                      _vm._v(
                        _vm._s(
                          " " +
                            _vm.$t("single_round") +
                            " #" +
                            _vm.roundId +
                            " "
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "round-accesses-header-flex" }, [
                _c("div", [
                  _c(
                    "h1",
                    { staticClass: "round-accesses-header-title" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("single_round") + " #" + _vm.roundId)
                        ),
                      ]),
                      _c("font-awesome-icon", {
                        staticClass: "icon-arrow-breadcrumb",
                        attrs: { icon: "fa-solid fa-chevron-right" },
                      }),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("round_accesses_breadcrumb"))),
                      ]),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "round-accesses-header-sub-title" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("round_accesses_subtitle")) + " "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "div",
              [
                _c("RoundAccessesTableComponente", {
                  attrs: { roundId: _vm.roundId },
                }),
              ],
              1
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }